import React, { useEffect, useState } from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const GenerateToken = () => {
  const [type, setType] = useState(null)
  useEffect(() => {
    const searchParam = new URLSearchParams(window.location.search);
    setType(searchParam.get("type"));
  }, []);
  return (
    <LayoutComponent>
      <h4>Generate API Access Token</h4>
      <div className="testimonials">
        <h5>Note</h5>
        <p>
          You must be a {type === "tool" ? "organization admin" : "site admin"}
          {' '}or project admin to <strong>generate an API access token</strong> for
          your GAINSS APIs.
        </p>
      </div>
      <div>
        <p>Follow these steps to generate an API access token:</p>
        <ul>
          <li>
            Navigate to{" "}
            {type === "tool" ? (
              <>
                <strong>Configurations</strong> in the project sidebar. Under
                the Automation sections, click on <strong>API token</strong>
              </>
            ) : (
              <strong>Project Settings &gt; Plugin Configuration</strong>
            )}
          </li>
          <li>
            Click on <strong>Generate GAINSS API Token</strong> to create a new
            token.
          </li>
          <li>
            The token will be valid for 90 days from the date of creation.
          </li>
          <li>
            Copy the token using the <strong>Click to Copy</strong> button and
            use it in your GAINSS API requests for authorization.
          </li>
        </ul>
      </div>
    </LayoutComponent>
  )
}

export default GenerateToken
